import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const PageContent = styled.div(
  ({ theme }) => css`
    p {
      font-family: ${theme.font.family.serif};
      font-size: ${rem(18)};
      margin-bottom: 0.5rem;

      ${theme.mediaUp.lg`
        font-size: ${rem(22)};
      `}
    }
  `
);
