import styled from 'styled-components';

export const Page = styled.article`
  padding: 2rem;
  flex: 1;

  ${props => props.theme.mediaUp.lg`
    padding: 3rem 5rem;
  `}
`;
