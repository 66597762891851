import React from 'react';
import { Page as StyledPage } from './styled';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';

function Page({ title, subtitle, content }) {
  return (
    <StyledPage>
      {(title || subtitle) && <PageHeader title={title} subtitle={subtitle} />}

      {content && <PageContent content={content} />}
    </StyledPage>
  );
}

export default Page;
