import styled from 'styled-components';
import { rem } from 'polished';

export const PageHeader = styled.div`
  margin-bottom: 2rem;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.font.family.serif};
  font-style: italic;
  font-size: ${rem(52)};
  margin-bottom: 0.25rem;
  line-height: 1;
  letter-spacing: -0.03em;

  ${({ theme }) => theme.mediaUp.lg`
    font-size: ${rem(80)};
  `}
`;

export const Subtitle = styled.h2`
  font-size: ${rem(14)};

  ${({ theme }) => theme.mediaUp.lg`
    font-size: ${rem(16)};
  `}
`;
