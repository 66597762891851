import React from 'react';
import { PageHeader as StyledPageHeader, Title, Subtitle } from './styled';

function PageHeader({ title, subtitle }) {
  return (
    <StyledPageHeader>
      {title && <Title>{title}</Title>}

      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </StyledPageHeader>
  );
}

export default PageHeader;
