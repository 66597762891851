import React from 'react';
import Page from '../components/Page';

const title = '404 Not Found';
const subtitle = 'Whoops!';
const content = `<p>Looks like someone is in the wrong place &nbsp; 👀</p>`;

function NotFound() {
  return <Page title={title} subtitle={subtitle} content={content} />;
}

export default NotFound;
